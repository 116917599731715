/* stylelint-disable max-line-length */
// ----------------------
// Bootstrap 5 defaults:
// ----------------------
// see node_modules\bootstrap\scss\_variables.scss

// $gray-100: #f8f9fa !default;
// $gray-200: #e9ecef !default;
// $gray-300: #dee2e6 !default;
// $gray-400: #ced4da !default;
// $gray-500: #adb5bd !default;
// $gray-600: #6c757d !default;
// $gray-700: #495057 !default;
// $gray-800: #343a40 !default;
// $gray-900: #212529 !default;

// $blue:    #0d6efd !default;
// $indigo:  #6610f2 !default;
// $purple:  #6f42c1 !default;
// $pink:    #d63384 !default;
// $red:     #dc3545 !default;
// $orange:  #fd7e14 !default;
// $yellow:  #ffc107 !default;
// $green:   #198754 !default;
// $teal:    #20c997 !default;
// $cyan:    #0dcaf0 !default;

// $primary:       $blue !default;
// $secondary:     $gray-600 !default;
// $success:       $green !default;
// $info:          $cyan !default;
// $warning:       $yellow !default;
// $danger:        $red !default;
// $light:         $gray-100 !default;
// $dark:          $gray-900 !default;

// $font-family-sans-serif:      system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
// $font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
// ----------------------

// TODO investigate variable overriding
// @import "../../../node_modules/bootstrap/scss/functions";
// @import "../../../node_modules/bootstrap/scss/variables";
@import "../base/fonts";

// Site fonts:
$font-family-montserrat: Montserrat, sans-serif;
$font-family-montserrat-bold: MontserratBold, sans-serif;
$font-family-montserrat-semi-bold: MontserratSemiBold, sans-serif;

// Site colors:
// #0a2240; // body color
// #528387; // h titles, label
// #f37120; // button color
// #f5f5f5; // form bg
// #c4c2c2; // dropshadow
// #e3e3e3; // footer bg
// #6f7170; // icon, footer text, small note text
// #84909f; // placeholder

// Boostrap overrides:

// Colors
$white:       #fff;
$gray-100:    #f3f1f2; // form bg
$gray-200:    #e6e2e4; // footer bg
$gray-300:    #dee2e6;
$gray-400:    #c4c2c2; // dropshadow
$gray-500:    #84909f; // placeholder
$gray-600:    #6f7170; // icon, footer text, small note text
$gray-700:    #4e444e;
$gray-800:    #333f48; // bottom footer
$gray-900:    #212529;
$black:       #1e0013;

$blue:        #45c7e0;
$indigo:      #8246af;
$purple:      #7a126b;
$pink:        #d63384;
$red:         #e1140a;
$orange:      #f26a52; // button color
$yellow:      #ffc107;
$green:       #6ac346;
$teal:        #528387; // h titles, label
$cyan:        #0dcaf0;
$purple-dark: #4d144a; // Button hover
$purple-light:#f1e1ed; // Button hover
$maroon:      #871c23; // Main headers
$maroon-dark: #64131e; // Sub headers

$primary:       $purple;    // main accent color, color of buttons etc.
$secondary:     $gray-800;  // the most prominent color, text, backgrounds, panels etc.
$success:       $green;
$info:          $blue;
$warning:       $yellow;
$danger:        #b8252e;
$light:         $gray-100;
$dark:          $gray-900;

$body-color:    $gray-800;

$font-family-sans-serif: $font-family-montserrat;

$border-radius: .25rem;

// Headings
$headings-font-family:        $font-family-montserrat;
$headings-color:              $maroon;
$h4-font-size:                1.44rem;
$h6-font-size:                1.15rem;

// Buttons
$input-btn-font-family: $font-family-montserrat;

// Forms
$input-border-color:  $primary;
$input-border-radius:  5px;

$form-label-font-size:    .875rem;
$form-label-color:        $teal;
$form-background:         $gray-100;

// Offcanvases
$offcanvas-padding-x: 2rem;
$offcanvas-backdrop-opacity: .05;

// Box shadow
$box-shadow-color: #00000029;

// Modal backdrop color
$modal-backdrop-bg: #aba8b1;

// Gradient color
$gradient-background: transparent linear-gradient(270deg, #7a126b 0%, #4d144a 100%) 0% 0% no-repeat padding-box;

// Footer
$footer-menu-color: #aba8b1;

$hr-color: #aba8b1;
