/* stylelint-disable property-no-vendor-prefix */
@import "variables";
@import "mixins";

html {
  font-size: 16px;

  @include respond(xs) {
    font-size: 11px;
  }

  @include respond(sm, md) {
    font-size: 12px;
  }
}

table {
  thead {
    tr {
      td {
        padding: 5px 10px;
        color: $light;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 10px 15px;
        border-bottom: 2px solid $gray-400;
      }
    }

    tr.expanded {
      td {
        border-bottom: unset;
      }
    }
  }
}

.content-text {
  font-family: $font-family-sans-serif;
}

.tooltip .tooltip-inner {
  color: $light;
  background-color: $secondary;
  font-family: $font-family-sans-serif;
}

.tooltip .arrow::before {
  border-top-color: $secondary;
}

.tooltip.show {
  opacity: 1;
}

h1 {
  font-size: 2.625rem;
  font-family: $font-family-montserrat-bold;
}

h2,
h3,
h4 {
  line-height: 2.15rem;
}

input::placeholder {
  font-size: .875rem;
  color: $gray-700;
}

input.form-control,
select.form-control {
  font-size: .875rem;
  color: $gray-700;
  height: 3rem;
}

strong,
.text-bold {
  font-family: $font-family-montserrat-bold;
}

p {
  color: $black;
}

.text-danger {
  color: $black !important;
}
