@font-face {
  font-family: Montserrat;
  src: url("../../assets/font/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: MontserratSemiBold;
  src: url("../../assets/font/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: MontserratBold;
  src: url("../../assets/font/Montserrat-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
