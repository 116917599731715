@import "variables";
@import "mixins";

.btn {
  height: 3rem;
  font-size: .875rem;
  font-family: $font-family-montserrat-bold;
  border-radius: unset;
}

.btn-primary,
.btn-outline-primary {
  padding: .4rem 1.2rem;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  color: $white !important;
}

.btn-outline-primary {
  background-color: $white;
  color: $primary !important;

  &:hover,
  &:focus-visible {
    color: $white !important;
  }
}

.btn-info {
  color: $white !important;
}

.btn-reset {
  color: $black !important;
  border: 1px solid $black;
  background: transparent;

  &:focus {
    color: $black !important;
    background: transparent;
  }

  &:hover {
    color: $white !important;
    background: $black;
  }
}
