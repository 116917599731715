@import "variables";

.modal-title {
  color: $gray-800;
}

.modal-content {
  padding: 1.5rem;
  color: $gray-800;
  border-radius: 0 !important;

  .modal-header {
    border-bottom: unset;
  }

  .modal-footer {
    border-top: unset;
    justify-content: start;

    button {
      width: unset !important;
    }
  }
}

.modal-backdrop {
  --bs-backdrop-bg: #{$modal-backdrop-bg};

  opacity: .4;
}
