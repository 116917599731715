@import "variables";
@import "mixins";

.list-wrapper {
  overflow: hidden;

  .table {
    font-size: .875rem;

    thead {
      background-color: $gray-700;
      color: $white;
      font-family: $font-family-montserrat-bold;
      height: 56px !important;

      @include respond(sm, md) {
        height: 50px !important;
      }

      .column-header {
        div {
          font-size: .875rem;
        }
      }

      th {
        padding: 0 .5rem;
      }
    }

    .item-row {
      background-color: $gray-100 !important;
      color: $black;

      .action-link.btn {
        height: 22px;
        width: 60px;
        font-size: 12.5px;
      }

      td.data-column {
        border-bottom-width: 2px;
      }
    }
  }

  .page-options-size {
    font-size: 15px;

    .dropdown {
      padding: 0 2px;
    }

    .dropdown-toggle:hover {
      color: $btn-link-hover-color !important;
    }
  }

  .page-options-pagination {
    .page-link:hover,
    .page-link {
      color: $secondary;
      background-color: $light;
      box-shadow: unset !important;
    }

    .page-item.active,
    .page-item:active {
      .page-link {
        background-color: $primary !important;
        color: $white !important;
      }
    }

    .page-item.disabled {
      .page-link {
        color: $gray-300 !important;
      }
    }

    .page-item a {
      font-size: 10px;
      font-family: $font-family-montserrat-bold;
      margin-left: 2px;
      margin-right: 2px;
      background-color: $white;
      border: 1px solid $gray-300;
    }

    .page-item.active a {
      border: 1px soild $primary;
    }
  }

  .mobile-data {
    hr: {
      color: $secondary !important;
    }
  }
}
