@import "variables";

.form-control {
  border-color: $gray-200;
  border-radius: $border-radius;

  &:disabled {
    background-color: $gray-200;
  }
}

lib-generic-select-form-control {
  fa-icon {
    color: $primary;

    &:hover {
      color: $btn-link-hover-color;
    }
  }
}
