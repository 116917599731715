/* You can add global styles to this file, and also import other style files */
@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

@import "../node_modules/bootstrap/scss/bootstrap";

@import "styles/base/base";
@import "styles/base/fonts";
@import "styles/base/typography";

@import "styles/components/buttons";
@import "styles/components/inputs";
@import "styles/components/tabs";
@import "styles/components/datepickers";
@import "styles/components/lists";
@import "styles/components/dropdowns";
@import "styles/components/forms";
@import "styles/components/modals";
@import "styles/components/tables";
