@import "variables";

.datepicker-container {
  position: relative;

  .datepicker-icon {
    cursor: pointer;
    position: absolute;
    right: .5rem;
    color: $primary;

    &:hover {
      color: $btn-link-hover-color;
    }
  }

  .ngb-dp-weekday {
    color: $dark;
  }
}
